import { render, staticRenderFns } from "./CrewForm.vue?vue&type=template&id=d2700f2e&"
import script from "./CrewForm.vue?vue&type=script&lang=js&"
export * from "./CrewForm.vue?vue&type=script&lang=js&"
import style0 from "./CrewForm.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports