<template>
  <vca-card class="shadowed">
    <CrewForm
      v-model="create"
      @submit="add"
      @remove_city="removeCity"
      @add_city="addCity"
    />
  </vca-card>
</template>

<script>
import CrewForm from "@/components/network/crews/CrewForm";
export default {
  name: "CrewsAdd",
  components: { CrewForm },
  computed: {
    create: {
      get() {
        return this.$store.state.crews.create;
      },
      set(value) {
        this.$store.commit("crews/create", value);
      },
    },
  },
  methods: {
    add() {
      if (!confirm(this.$t("crews.messages.add"))) {
        return false;
      }
      this.$store.dispatch({ type: "crews/create" });
    },
    removeCity(index) {
      this.$store.commit("crews/remove_city", index);
    },
    addCity(val) {
      if (this.create.cities == undefined) {
        this.create["cities"] = [];
      }
      if (this.create.cities.find((el) => el.city == val.city)) {
        this.notification({
          title: this.$t("messages.title.warn"),
          body: this.$t("messages.crew.city_conflict"),
          type: "warn",
        });
        return;
      }
      this.$store.commit("crews/add_city", val);
    },
  },
};
</script>
<style lang="scss">
.add-city {
  button {
    margin: 5px 0 10px 10px;
  }
}
</style>
