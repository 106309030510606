<template>
  <vca-card>
    <h2>{{ $t("crews.add.header") }}</h2>
    <Add />
  </vca-card>
</template>

<script>
import Add from "@/components/network/crews/CrewAdd";
export default {
  name: "NetworkCrewAdd",
  components: { Add },
  /*
    created() {
        this.$store.dispatch({type: 'eventAdd'})
    }*/
};
</script>
