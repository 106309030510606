<template>
  <vca-column>
    <h3>{{ $t("crews.active.label") }}</h3>
    <vca-row>
      <vca-row>
        <input type="radio" v-model="value.status" id="active" value="active" />
        <label for="active">{{ $t("crews.active.active") }}</label>
      </vca-row>
      <vca-row>
        <input
          type="radio"
          v-model="value.status"
          id="inactive"
          value="inactive"
        />
        <label for="inactive">{{ $t("crews.active.inactive") }}</label>
      </vca-row>
      <vca-row>
        <input
          type="radio"
          v-model="value.status"
          id="dissolved"
          value="dissolved"
        />
        <label for="dissolved">{{ $t("crews.active.dissolved") }}</label>
      </vca-row>
    </vca-row>
    <h3>{{ $t("crews.asps_selection.label") }}</h3>
    <vca-row>
      <vca-row>
        <input
          type="radio"
          v-model="value.asp_selection"
          @click="setWarning()"
          id="asp_selection_active"
          value="active"
        />
        <label for="asp_selection_active">{{
          $t("crews.asps_selection.active")
        }}</label>
      </vca-row>
      <vca-row>
        <input
          type="radio"
          disabled
          v-model="value.asp_selection"
          id="asp_selection_selected"
          value="selected"
        />
        <label for="asp_selection_selected">{{
          $t("crews.asps_selection.selected")
        }}</label>
      </vca-row>
      <vca-row>
        <input
          type="radio"
          v-model="value.asp_selection"
          @click="setWarning()"
          id="asp_selection_inactive"
          value="inactive"
        />
        <label for="asp_selection_inactive">{{
          $t("crews.asps_selection.inactive")
        }}</label>
      </vca-row>
    </vca-row>
    <vca-card class="shadowed" v-if="aspSelectionWarning">{{
      $t("crews.asp_selection.reset_warning")
    }}</vca-card>
    <div>
      <h3>{{ $t("crews.name.label") }}</h3>
      <vca-input
        v-model.trim="value.name"
        ref="name"
        :rules="$v.value.name"
        :placeholder="$t('crews.name.placeholder')"
        :errorMsg="$t('crews.name.errorMsg')"
      />
    </div>
    <div>
      <h3>{{ $t("crews.email.label") }}</h3>
      <vca-input
        v-model.trim="value.email"
        ref="email"
        :rules="$v.value.email"
        :placeholder="$t('crews.email.placeholder')"
        :errorMsg="$t('crews.email.errorMsg')"
      />
    </div>
    <div>
      <h3>{{ $t("crews.abbreviation.label") }}</h3>
      <vca-input
        v-model.trim="value.abbreviation"
        ref="abbreviation"
        :rules="$v.value.abbreviation"
        :placeholder="$t('crews.abbreviation.placeholder')"
        :errorMsg="$t('crews.abbreviation.errorMsg')"
      />
    </div>
    <div>
      <h3>
        {{ $t("crews.mattermost.label") }}
      </h3>
      <vca-input
        v-model="value.mattermost_username"
        :placeholder="$t('crews.mattermost.placeholder')"
      />
    </div>
    <div>
      <h3>{{ $t("crews.additional.label") }}</h3>
      <vca-textarea
        v-model.trim="value.additional"
        :placeholder="$t('crews.additional.placeholder')"
      />
    </div>
    <div>
      <h3>{{ $t("crews.cities.label") }}</h3>
      <vca-field-row>
        <vca-location
          ref="cities"
          :rules="$v.value.cities"
          :errorMsg="$t('crews.cities.errorMsg')"
          v-model="coe"
          :value="coe"
          type="geocode"
        ></vca-location>
        <div v-if="coe" class="add-city">
          <button class="vca-button-small" @click="addCity()">
            {{ $t("button.add") }}
          </button>
        </div>
      </vca-field-row>
      <div class="tags-container">
        <div class="tag" v-for="(res, index) in value.cities" :key="res.city">
          <div class="tag-label">{{ res.city }} ({{ res.country }})</div>
          <div class="cancel" @click="removeCity(index)">X</div>
        </div>
      </div>
    </div>
    <div>
      <button class="vca-button" @click="add()">
        {{ $t("button.save") }}
      </button>
    </div>
  </vca-column>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CrewForm",
  data() {
    return {
      coe: null,
      aspSelectionWarning: false,
      doSetWarning: false,
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  created() {
    this.doSetWarning = this.value.asp_selection == "selected";
  },
  computed: {
    ...mapGetters({
      validation: "crews/validations",
    }),
  },
  validations() {
    return this.validation;
  },
  methods: {
    setWarning() {
      this.aspSelectionWarning = this.doSetWarning;
    },
    add() {
      if (this.$v.$invalid) {
        this.$refs.name.validate();
        this.$refs.email.validate();
        this.$refs.abbreviation.validate();
        this.$refs.cities.validate();
      } else {
        this.$emit("submit");
      }
    },
    removeCity(index) {
      this.$emit("remove_city", index);
    },
    addCity() {
      this.$emit("add_city", this.coe);
      this.coe = null;
    },
  },
};
</script>
<style lang="scss">
.add-city {
  button {
    margin: 5px 0 10px 10px;
  }
}
</style>
